import { render, staticRenderFns } from "./gongan.vue?vue&type=template&id=5fc85172&scoped=true&"
import script from "./gongan.vue?vue&type=script&lang=js&"
export * from "./gongan.vue?vue&type=script&lang=js&"
import style0 from "./gongan.vue?vue&type=style&index=0&id=5fc85172&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc85172",
  null
  
)

export default component.exports
<template>
    <div>
        <span class="text">智慧公安</span>
        <el-carousel trigger="click" :autoplay="true" height="700px" indicator-position="outside" class="Top">
        <el-carousel-item :key="1" >
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case20.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：武汉市公安局交通管理局</div>
                    <div class="box-text-text2">工程内容：第七届世界军人运动会信号机项目、机房系统工程、红色警营展馆信息化</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="2">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case21.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：某省公安厅</div>
                    <div class="box-text-text2">工程内容：大数据中心机房、信息系统集成、综合安防系统</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="3">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case22.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省黄石市公安局</div>
                    <div class="box-text-text2">工程内容：指挥中心小间距LED大屏系统、监控分系统、应急调度指挥系统、控制系统、会议系统、内部对讲系统、综合布线系统、机房系统工程(监控中心及办公区装饰装修、电气安装系统、UPS电源系统精密空调系统、防雷接地系统、环境监控系统、消防报警及灭火工程、弱电系统、监控大屏系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="4">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case23.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：通城县公安局</div>
                    <div class="box-text-text2">工程内容：平安城市三期视频监控系统、大楼弱电信息系统，智能楼宇系统、智慧安防平台、公安110指挥中心、大数据中心</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="5">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case24.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：武汉市城市路桥收费管理中心</div>
                    <div class="box-text-text2">工程内容：机房系统工程(监控中心及办公区装饰装修、电气安装系统、 UPS电源系统、精密空调系统、防雷接地系统环境监控系统、消防报警及灭火工程、弱电系统、监控大屏系统)</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="6">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case25.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：郑州市公安局监所管理支队</div>
                    <div class="box-text-text2">工程内容：数据中心封闭冷通道系统、数据中心UPS系统、数据中心空调、新风及排烟系统、动力环境监控、数据中心管理设备、电气工程、消防灭火系统、机房综合布线、机房装修、防雷接地工程等各个子系统的建设规划、方案设计、施工安装。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="7">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case26.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    <div class="box-text-text1">建设单位：湖北省恩施监狱</div>
                    <div class="box-text-text2">工程内容：监控系统、门禁系统、AB出入监管理系统、报警系统、智能监仓对讲系统、机房工程系统。</div>
                </div>
            </div>
        </el-carousel-item>
        <el-carousel-item :key="8">
            <div class="box">
                <div class="box-img">
                    <img src="@/assets/img/case27.jpg" class="box-img-img1">
                </div>
                <div class="box-text">
                    
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：武汉市公安局交通管理局</div>
                        <div class="box-text-text2">工程内容： 机房系统I程(监控中心及办公区装饰装修、电气安装系统、UPS电源系统、精密空调系统、防雷接地系统、环境监控系统、消防报警及灭火工程、弱电系统、监控大屏系统)</div>
                        
                    </div>
                    <div class="box-text-text6">
                        <div class="box-text-text1">建设单位：武汉市公安局交通管理局洪山大队</div>
                        <div class="box-text-text2">工程内容：建筑智能化弱电系统、有线电视系统、安防监控系统、信息发布系统、排队叫号系统、门禁管理系统(含停车管理)、会议系统(电视电话会议室、大会议室)、电子巡更系统</div>
                        
                    </div>
                </div>
            </div>
        </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="less">
.box{
    width: 100%;
    //height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    &-img{
        width: 95%;
        //height: 80%;
        display: flex;
        justify-content: center;
        position: relative;
        flex-wrap: wrap;
        top: 0rem;
        &-img1 {
            margin-top: 1rem;
            width: 100%;
            //height:100%;
        }
    }
    &-text{
        top: 4rem;
        line-height:1.5rem;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        width: 95%;
        font-size: 18px;
        font-weight: 600;
        position: relative;
        &-text1{
            text-align: start;
            width: 100%;
            height: 30px;
            margin-top: 0rem;
            //display: flex;
            position: relative;
            float: inline-start;
        }
        &-text2{
            //display: flex;
            flex-wrap: wrap;
            width: 100%;
            //height: 50px;
            position: relative;
        }
        &-text3{
            display: flex;
            position: relative;
            width: 7%;
        }
        &-text4{
            display: flex;
            position: relative;
            width: 93%;
        }
        &-text5{
            display: flex;
            position: relative;
            width: 100%;
            justify-content: center;
            font-weight: bold;
        }
        &-text6{
            //display: flex;
            position: relative;
            width: 49%;
            top: 1rem;
        }
    }
}
.text{
    margin-top: 2rem;
    line-height:2.5rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
}
.block{
    width: 100%;
}
 .el-carousel__item {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    border: #475669;
 }
 .el-carousel__item:nth-child(n) {
     background-color: #ffffff;
  }
  .Top{
    margin-top: 1rem;
}
</style>
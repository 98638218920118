<template>
    <el-container class="honor">
     <el-header class="honor-top">
       <Header />
     </el-header>
     <el-main class="honor-center">
      <div class="block"><gongan></gongan></div>
     </el-main>
     <el-footer class="honor-footer">
       <Footer />
     </el-footer>
   </el-container>
 </template>

 <script>
import Header from './components/Header.vue'
import gongan from './caseCenter/gongan.vue'
import Footer from './components/Footer.vue'
export default {
components:{
  Header,Footer,gongan
}
}
</script>

<style scoped lang="less">
.honor{
  width: 100%;
  &-top{
    z-index: 999;
    position: fixed;
    top: 0px;
    width: 100%;
    background-color:#fff;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  &-center{
    position: relative;
    top: 4rem;
    width: 100%;
    margin: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    background-color: #f4f4f4;
  }
   .honor-footer{
     top: 3rem;
     position: relative;
     width: 100%;
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }
}
.block{
  width: 70%;
}
</style>